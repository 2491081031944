import React from "react"
import { Link } from "gatsby"

// Components
import Logo from "../components/image"
import SEO from "../components/seo"
import Newsletter from "../components/newsletter"

const IndexPage = () => (
 <>
    <SEO title="Home" />
    <section className="grid grid-cols-12 bg-gradient-to-r from-white via-ms_turquoise to-ms_blue h-full px-3 lg:px-0">
    <div className="col-span-12 text-center">
    <div className="mx-6" style={{ maxWidth: `250px`, marginRight: `auto`, marginTop: `1em`, padding: `6px 9px`, borderRadius: `5em` }}>
      <Logo />
    </div>
      <h1 className="text-2xl lg:text-6xl pt-16 text-gray-100"><span className="text-ms_darkgrey">Make Transformation Visible.</span></h1>
      <h2 className="text-sm lg:text-xl my-3"><span className="text-ms_grey">We enable course <span className="">creators</span> to empower their <span className="">students</span> learning, application and results.</span></h2>
    <div className="mt-16 mb-12">
      <Link to="/application-form">
        <button className="py-2 px-3 hover:bg-ms_grey m-3 bg-ms_blue font-bold rounded-md text-white">I want MySnapshot for my course</button>
      </Link>
    </div>
    <Newsletter/> 
    </div>
    </section>
</>
)

export default IndexPage
