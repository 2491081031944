import React from "react"
import { navigate } from 'gatsby-link'
import { Link } from "gatsby"


    function encode(data) {
        return Object.keys(data)
          .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
          .join('&')
      }
      
      export default function Newsletter() {
        const [state, setState] = React.useState({})
      
        const handleChange = (e) => {
          setState({ ...state, [e.target.name]: e.target.value })
        }
      
        const handleSubmit = (e) => {
          e.preventDefault()
          const form = e.target
          fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
              'form-name': form.getAttribute('name'),
              ...state,
            }),
          })
            .then(() => navigate(form.getAttribute('action')))
            .catch((error) => alert(error))
        }

    return (
        <section className="text-center h-80 md:h-screen lg:h-80">
            <div className="p-5">
                <p className="text-ms_darkgrey">Get updates from MySnapshot</p>
                <div className="">
                    <form 
                    method="post" 
                    name="newsletter" 
                    data-netlify-honeypot="bot-field" 
                    data-netlify="true"
                    action="/thanks/"
                    onSubmit={handleSubmit}
                    >
                        <input 
                        className="mt-2 w-full lg:w-1/4 text-center lg:text-left bg-transparent shadow placeholder-ms_darkgrey rounded focus:outline-none focus-within:text-gray-900 py-2 px-3" 
                        placeholder="Your email" 
                        type="email" 
                        name="email"
                        onChange={handleChange}
                        />

                        <input 
                        type="hidden" 
                        name="form-name" 
                        value="newsletter" />

                        <button 
                        type="submit" 
                        className="mt-3 lg:mt-0 bg-ms_blue hover:bg-ms_grey rounded-md py-2 px-3 text-white font-bold ml-3">Sign up</button>
                    </form>
                </div>
                <p className="text-xs my-5">Made with ❤️ by <a href="https://twitter.com/CharlotCrowther" target="_blank" rel="noopener noreferrer"><span className="text-gray-800 hover:text-gray-600">@CharlotCrowther</span></a></p>
                <Link to="/privacy-notice">
                <p className="text-xs"><span className="text-ms_darkgrey">Privacy Notice</span></p>
                </Link>
            </div>
        </section>
    )
}
